<template>
  <transition name="modal">
    <div class="modal-backdrop" @click.self="close">
      <div class="modal">
        <div class="modal-title center">Pertanyaan</div>
        <textarea v-model="propsQuestion" />
        <div class="center">
          <button type="button" @click="sendQuestion">KIRIM</button>
          <button type="button" style="background-color: #ffff; border: 1px solid #ffab40; color: #ffab40" @click="close">BATAL</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'question-modal',
  props: {
    close: {
      type: Function
    },
    question: {
      type: String
    },
    sendQuestion: {
      type: Function
    }
  },
  computed: {
    propsQuestion: {
      get() {
        return this.question
      },
      set(val) {
        this.$emit('handleMutateQuestion', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 999;
  transition: opacity 0.2s ease;
}
.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 330px;
  border-radius: 8px;
  position: relative;
  padding: 30px 40px;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  &-title {
    color: #333333;
    margin-bottom: 15px;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
  }
  textarea {
    border-radius: 7px 7px 0px 0px;
    width: 100%;
    height: 100%;
    resize: none;
    border: 1px solid #c4c4c4;
    border-bottom: 1px solid #ffffff;
    padding: 10px;
    outline: none;
  }
  button {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    box-sizing: border-box;
    padding: 5px;
    border: none;
    height: 35px;
    width: 50%;
    color: #ffffff;
    background-color: #ffab40;
    border: 1px solid #ffab40;
    cursor: cursor;
    outline: none;
    opacity: 1;
    transition: 0.1s;
    margin-bottom: 10px;
  }
  button:hover {
    opacity: 0.8;
  }

  .fadeIn-enter {
    opacity: 0;
  }

  .fadeIn-leave-active {
    opacity: 0;
    transition: all 0.2s step-end;
  }

  .fadeIn-enter .modal,
  .fadeIn-leave-active.modal {
    transform: scale(1.1);
  }

  .font-custom {
    font-size: 18px;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .margin {
    margin-top: -10px;
  }
}
</style>
